<template>
    <li class="flex-container flex-col">
        <div :class="className + ' advert-element lh24'">
            <div v-if="data.advert" class="layout-list-item-title nomargin flex-container p8">
                    {{data.advert.name}}
            </div>
        </div>
         
        <ul v-if="isOpen && data.advert?.ads && data.advert.ads.length >0 " class="pl24 mb8 mt8 layout-list">
            <FacebookAdvertPostAnalyticsElement 
                v-for="ad in data.advert.ads" 
                :key="ad.postId" 
                :post="ad"
                :editing="editing"
                :removable="false"
                :className="'flex-container layout-list-item lh24'"
            ></FacebookAdvertPostAnalyticsElement>
        </ul>
        <FacebookInsight
            :insight="insights"
        ></FacebookInsight>
    </li>
    
</template>
<script>
import {ref,reactive} from 'vue'
import {getFacebookAdvertInsightTotals} from '@/utils/AnalyticsFunctions'
import FacebookAdvertPostAnalyticsElement from '@/components/campaigns/analytics/facebook/FacebookAdvertPostAnalyticsElement'
import FacebookInsight from '@/components/campaigns/analytics/facebook/FacebookInsight'
export default {
    emits:['remove-advert','edit-advert'],
    props:{
        advert:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item flex-container'
        },
        editing:{
            type:Boolean,
            default:true
        },
        open:{
            type:Boolean,
            default:false
        }
    },
    components:{
       FacebookAdvertPostAnalyticsElement,
       FacebookInsight
    },
    setup(props){

        const isOpen = ref(props.open)
        const data = reactive({
            advert:props.advert
        })
        
        const insights = getFacebookAdvertInsightTotals(data.advert.ads)
        
        return{
            isOpen,
            data,
            insights
        }
    }
}
</script>
<style lang="postcss">
    .adverts-container{
        & label{
            & h3{
                font-size: .9em;
            }
            
        }
    }
</style>