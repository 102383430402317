<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            
            <div class="overlay-panel rel">
                <div class="flex-container flex-col p24">
                    <div class="panel-header ">
                        <div class="mini-logo"></div>
                        <h2 class="panel-title nomargin bebas">
                             access token scaduto
                        </h2>
                    </div>
                    <label class="flex-container flex-col flex-0 mb8 pt24">
                        <div class="flex-container heading-and-error flex-start-align no-grow ">
                            <h3 class="bebas nomargin">
                                Rinnova access Token {{resettingName ? ' : '+ resettingName : ''}}
                            </h3>
                        </div>
                        <p>
                            esegui il login con l'account
                            di amministrazione dell'app Inflooendo
                        </p>
                    </label>
                </div>  
                <div class="flex-container flex-center-justify pb24">
                    <FacebookWrapper
                        v-if="!loading" 
                        :fbUser="fbUser"
                        :message="'Rinnova access token'"
                        @fb-login="onFacebookLog"
                        @fb-no-login="onFacebookNoLog"
                    ></FacebookWrapper>
                    <p v-else>
                        Attendere prego, reset in corso...
                    </p>
                </div>  
                <div class="flex-container abs panel-footer w100">
                    <button 
                        class="w100 deny noborder nopadding bebas" 
                        @click="onClose"
                    >
                        annulla
                    </button>
                    <button
                        :disabled="!canSubmit" 
                        class="w100 noborder nopadding bebas" 
                        @click="onSubmit"
                    >
                        Invia 
                    </button>
                </div>
            </div> 
         </div>
      
    </teleport>
</template>
<script>
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
//import {useRoute} from 'vue-router'
import {API} from '@/api/API'
import FacebookWrapper from '@/components/social/facebook/FacebookWrapper'
import { ApiEndPoints } from '@/api/Endpoints'
import {useCapabilities} from '@/utils/Capabilities'
export default {
  props:{
      socialId:null
  },
  emits: ['close'],
  components:{
      FacebookWrapper
  },
  setup(props, {emit}){
    const store = useStore()  
    const canSubmit = ref(false)  
    const token = ref(null)  
    const loading = ref(false)  
    const fbUser = ref(null)  
    //const route = useRoute()

    const resetting = computed(()=>{
        return store.getters['reset/resettingFacebook']
    })
    const resettingID = computed(()=>{
        return store.getters['reset/resettingFacebookId']
    })
    const resettingName = computed(()=>{
        return store.getters['reset/resettingFacebookName']
    })


    const onTokenInput = ({text}) =>{
        token.value = text  
        canSubmit.value = token.value?.length > 2 
    }

    const onSubmit = async () => {
        try {
            await store.dispatch('social/resetFacebookTokens', token.value)
            await store.dispatch('messages/message', {
                title: 'Token Rinnovati',
                message:'Token Rinnovati correttamente'
            })
            emit('close')
        } catch (error) {
            await store.dispatch('messages/message', {
                title:'errore durante il reset dei token ', 
                message:error.message 
            })
            emit('close')
        }
        
    }

    const onClose = () => {
        store.commit('reset/facebook_reset_oauth_close')
        emit('close')
    }

    const onFacebookLog = async (authResponse)=>{
            loading.value = true
            try {
                if(authResponse.userID === process.env.VUE_APP_FACEBOOK_APP_USER_ID){
                    await API.post(ApiEndPoints.SOCIAL_FB+'/accounts/'+ resettingID.value+'/accessToken/refresh',{
                        facebookUserId:authResponse.userID,
                        accessToken:authResponse.accessToken
                    })
                } else {
                    const { isAdmin, isPublisher/* , isConcessionaire */ } = useCapabilities()
                    if(isAdmin()){
                        await API.post(ApiEndPoints.SOCIAL_FB+'/accounts/'+ resettingID.value+'/accessToken/refresh',{
                            useLongLivedAccessToken:true
                        })
                    } else if(isPublisher() /* || isConcessionaire() */){
                        const endpoint = ApiEndPoints.PUBLISHERS+'/social/facebook/accounts/'+ resettingID.value+'/accessToken/refresh'
                        await API.post(endpoint,{
                            facebookUserId:authResponse.userID,
                            accessToken:authResponse.accessToken,
                        })
                    } 
                }
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:"errore durante l'aggiornamento del token", 
                    message:error.message 
                })
            }
            store.commit('reset/facebook_reset_oauth_close')
            loading.value = false
            emit('close')
        /* } */
    }
    const onFacebookNoLog = (/* authResponse */)=>{
        //console.log('onFacebookNoLog')
        //console.log(authResponse)
    }

    return {
        onTokenInput,
        canSubmit,
        onSubmit,
        onClose,
        onFacebookLog,
        onFacebookNoLog,
        fbUser,
        resetting,
        loading,
        resettingName
    }
  }
}
</script>
<style lang="postcss">

.overlay-panel button.deny {
    color: white;
    background-color: black;
}
</style>