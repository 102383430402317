/**
 * Controlla se due array contengono gli stessi elementi
 * 
 * @param {Array} a 
 * @param {Array} b 
 * @returns Boolean
 */
export const arraysContainSame = (a, b)=> {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every(el => b.includes(el));
}