export const loadFacebookSDK = async ()=> { 
    await fetchScript('https://connect.facebook.net/en_US/sdk.js', 'facebook-jssdk');
    await initFacebook();
}

export const getFacebookSdk = () =>{
    return window.FB
}

export function fetchScript (url, id) {
    return new Promise(function (resolve, reject) {
      const script = document.createElement('script')
      if(id !== undefined)
          script.id = id
      document.body.appendChild(script)
      script.onload = resolve
      script.onerror = reject
      script.async = true
      script.src = url
    })
}

export const initFacebook = async () => {
    return new Promise(function (resolve, reject) {
        try {
            window.fbAsyncInit = function() {
                window.FB.init({
                  appId: process.env.VUE_APP_FACEBOOK_APP_ID,
                  cookie     : true,
                  autoLogAppEvents: true,
                  xfbml      : true,
                  version: 'v10.0'
                });
                resolve()
            };
        } catch (error) {
           reject(error)
        }   
    })
    
}


export const checkLoginStatus = ()=>{
    console.log('checkLoginStatus')
    return new Promise(function (resolve, reject) {
        window.FB.getLoginStatus(function(response) {

            if(response.authResponse){
                console.log('resp')
                resolve(response)
            } else {
                console.log('no')
                reject(response)
            }
        })
    })  
}

export const doFbLogin =(options)=>{ 
    return new Promise(function (resolve, reject) {
        window.FB.login(function(response) {
            if (response.authResponse) {
                resolve(response)
            } else {
                reject(response)
            }
        },  options)
    }) 
}


export const parseFacebookLink = async (url)=>{
    const [origin, socialId,path,postId] = url.split('/').slice(2)
    if(origin !== 'www.facebook.com' && origin !== 'business.facebook.com' ){
        return false
    }
    if(path !== 'posts' || !socialId || !postId){
       return false
    }
    return {
        socialId: socialId,
        postId:socialId+'_'+postId
    }
}


