<template>
    <div :class="className"> 
         <SelectInput
            :label="'Tipologia prezzo'"
            :name="'priceType'"
            :id="'priceType'"
            :value="data.priceType"
            :editing="editing"
            :placeholder="'tipologia di prezzo'"
            :options="['Fixed', 'Variable']"
            :className="'flex-container flex-col mb8  ml8'"
            @select-change="onPriceTypeChange"
        ></SelectInput>          
        <PriceInput
            v-if="data.priceType === 'Fixed'"
            :label="'prezzo'"
            :name="'price'"
            :id="'price'"
            :value="data.price"
            :editing="editing"
            :placeholder="'inserisci prezzo fisso'"
            :className="'flex-container flex-col mb8 pl8'"
            @input="onPriceInput" 
        >
        </PriceInput>
        <div
            v-else
            class="flex-container flex-col ml8" 
        >
            <PriceInput
                :label="'prezzo Organico'"
                :value="data.organicCoveragePrice"
                :name="'organicCoveragePrice'"
                :id="'organicCoveragePrice'"
                :editing="editing"
                :placeholder="'inserisci prezzo organico'"
                :className="'flex-container flex-col mb8'"
                @input="onPriceInput"
            >
            </PriceInput>
            <PriceInput
                :label="'prezzo sponsorizzato'"
                :value="data.sponsoredCoveragePrice"
                :editing="editing"
                :name="'sponsoredCoveragePrice'"
                :id="'sponsoredCoveragePrice'"
                :placeholder="'inserisci prezzo sponsorizzato'"
                :className="'flex-container flex-col mb8'"
                @input="onPriceInput"
            >
            </PriceInput>
        </div>
    </div>
</template>
<script>
import {reactive} from 'vue'
import PriceInput from '@/components/formelements/PriceInput'
import SelectInput from '@/components/formelements/SelectInput'
export default {
    emits:['input', 'change'],
    components:{
        PriceInput,
        SelectInput
    },
    props:{
        priceType:{
            type:String,
            default: 'Fixed'
        }, 
        price:{
            type:[Number, String],
            default: 0
        }, 
        organicCoveragePrice:{
            type:Number,
            default: 0
        },
        sponsoredCoveragePrice:{
            type:Number,
            default: 0
        },
        editing:{
            type:Boolean,
            default: true
        },
        className:{
            type:String,
            default: 'flex-container flex-col mb8 pl8'
        }, 
        
    },
    setup(props, {emit}){
        const data = reactive({...props})

        

        const onPriceTypeChange = (type) => {
            data.priceType = type
            emit('change', type)
        }
        const onPriceInput = ({text, name}) =>{
            emit('input', {text:text , name:name})
        }
        return{
            data,
            onPriceTypeChange,
            onPriceInput
        }
    }
}
</script>