<template>
    <li :class="className + ' fb-post-analytics-element pl8'">
        <FacebookPostImage
            :url="post.fullPicture"
            :id="post.postId"
        ></FacebookPostImage>
        <div class="w100">
            <p class="layout-list-item-subtitle f12 nomargin pl8 lh24"><i>{{id || 'no id from db :S'}}</i></p>
            <div class="layout-list-item-title nomargin pl8 lh24">
                {{post.name}} 
            </div>
        </div>
        
        <InflooButton
                @click="() =>openLocation(post.permalink)"
                :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify '"
        >
                vedi
        </InflooButton>
    </li>
</template>
<script>
import { computed, ref} from 'vue'
import {useStore} from 'vuex'
import InflooButton from '@/components/buttons/InflooButton'
import {openLocation} from '@/utils/CampaignFunctions'

import FacebookPostImage from '@/components/campaigns/facebook/posts/FacebookPostImage'
export default {
    props:{
        post:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item flex-container flex-center-align'
        }
        
    },
    components:{
        InflooButton,
        FacebookPostImage
    },
    setup(props){
        const store = useStore()
        const socialName = computed(()=>{
            return store.getters['social/social'].find(s => s.socialId === props.post.socialId)?.name || ''
        })
       let id = ref(props.post.postId)
       if(!id.value){
           try {
               const arr = props.post.permalink.split('/').slice(3)
               id.value = arr[0]+'_'+arr[2]
           } catch (error) {
              id.value = 'id not found' 
           }
           
       }
       
        return{
            socialName,
            openLocation,
            id
        }
    }
}
</script>
<style scoped>
.layout-content .layout-list-item a {
    
    font-weight: normal;
}
.layout-content input[type="reset"] {
    height: 40px;
}
.h40px{
    height: 40px;
}

.fb-post-analytics-element img {
    height: 36px;
    width: 36px;
    object-fit: cover;

}


</style>