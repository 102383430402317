<template>
    <li :class="className + ' post-analytics-element flex-col'">
        <a 
            @click="()=>$emit('list-select', post)"
            class="layout-list-item-btn flex-container"
        >
            <FacebookPostImage
                :url="post.fullPicture"
                :id="post.postId"
            ></FacebookPostImage>
            <div class="flex-container flex-col w80">
                <p class="layout-list-item-subtitle f12 nomargin pl8 lh24"><i>{{id || 'no id from db :S'}}</i></p>
                <div class="layout-list-item-title nomargin pl8 lh24 pr8">
                        {{post.story ? post.story : post.message}} 
                </div>
            </div>
            
            <InflooButton
                 @click="() =>openLocation(post.permalink)"
                 :className="'add-post-to-campaign button primary flex-container flex-center-align flex-center-justify '"
            >
                    vedi
            </InflooButton> 
        </a>
        <text-input
            v-if="isAdmin() || isPublisher()"
            :label="'Costo'"
            :value="formatNumber(post.cost) + ' €'"
            :editing="false"
            :name="'clicksLink'"
            :className="'flex-container flex-col flex-start-align flex-start-justify pt16 lh24'"
            :labelClassName="'nomargin'"
        >
        </text-input>
        
        <AdvertiserFacebookInsight
            v-if="isAdvertiser()"
            :insight="post.insight"
        ></AdvertiserFacebookInsight> 
        <FacebookInsight
            v-else
            :insight="post.insight"
        ></FacebookInsight> 
        
    </li>
</template>
<script>
import {ref} from 'vue'
import {useCapabilities} from '@/utils/Capabilities'
import FacebookInsight from '@/components/campaigns/analytics/facebook/FacebookInsight'
import AdvertiserFacebookInsight from '@/components/campaigns/analytics/facebook/AdvertiserFacebookInsight'
import InflooButton from '@/components/buttons/InflooButton'
import FacebookPostImage from '@/components/campaigns/facebook/posts/FacebookPostImage'
import TextInput from '@/components/formelements/TextInput'
import {openLocation} from '@/utils/CampaignFunctions'
import {formatNumber} from '@/utils/NumberFunctions'
export default {
    props:{
        post:{
            type:Object,
            default:()=>{}
        },
        className:{
            type:String,
            default:'layout-list-item'
        },
    },
    components:{
       FacebookInsight,
       AdvertiserFacebookInsight,
       InflooButton,
       FacebookPostImage,
       TextInput
    },
    setup(props){
       const {isAdmin, isPublisher, isAdvertiser} = useCapabilities()
       let id = ref(props.post.postId)
       if(!id.value){
           try {
               const arr = props.post.permalink.split('/').slice(3)
               id.value = arr[0]+'_'+arr[2]
           } catch (error) {
              id.value = 'id not found' 
           }
           
       }
        
        return{
            openLocation,
            id,
            isAdmin,
            isPublisher,
            isAdvertiser,
            formatNumber
        }
    }

}
</script>
<style>
.post-analytics-element img {
    height: 36px;
    width: 36px;
    object-fit: cover;

}
.post-analytics-element label h3 {
    font-size: .9em;
}
.add-post-to-campaign{
     max-width: 68px;
}

</style>