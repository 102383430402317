<template>
     <div
        v-if="insight" 
        class="flex-container flex-col lh24 pt16">
            <div class="flex-container flex-col mb16">
                <div class="flex-container no-grow">
                    <text-input
                        :label="'Click sul link'"
                        :value="formatNumber(insight.linkClicks) || 0"
                        :editing="false"
                        :name="'clicksLink'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Altri Click'"
                        :value="formatNumber(insight.otherClicks) || 0"
                        :editing="false"
                        :name="'otherClicks'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'Visualizzazioni foto'"
                        :value="formatNumber(insight.photoView )|| 0"
                        :editing="false"
                        :name="'photoView'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container no-grow">
                    <text-input
                        :label="'reaction'"
                        :value="formatNumber(insight.reactions) || 0"
                        :editing="false"
                        :name="'reactions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'commenti'"
                        :value="formatNumber(insight.comments) || 0"
                        :editing="false"
                        :name="'comments'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'condivisioni'"
                        :value="formatNumber(insight.shared) || 0"
                        :editing="false"
                        :name="'shared'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container  no-grow">
                    <text-input
                        :label="'impressions'"
                        :value="formatNumber(insight.impressions) || 0"
                        :editing="false"
                        :name="'impressions'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'copertura'"
                        :value="formatNumber(insight.totalCoverage) || 0"
                        :editing="false"
                        :name="'totalCoverage'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                    <text-input
                        :label="'dummy'"
                        :value="''"
                        :editing="false"
                        :className="'flex-container flex-col flex-start-align flex-start-justify unseen'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
            </div>
            <hr>
            <div 
                v-if="campaign.mediaType=== 'Video'"
                class="flex-container flex-start-align mb16"
            >
                <div class="flex-container flex-col flex-start-align">
                    <text-input
                        :label="'3 secondi'"
                        :value="formatNumber(insight.videoViews3S) || 0"
                        :editing="false"
                        :name="'videoViews3S'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container flex-col flex-start-align">
                    
                    <text-input
                        :label="'10 secondi'"
                        :value="formatNumber(insight.videoViews10S) || 0"
                        :editing="false"
                        :name="'video-view-10-secondi'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
                <div class="flex-container flex-col flex-start-align">
                    <text-input
                        :label="'15 secondi'"
                        :value="formatNumber(insight.videoViews15S) || 0"
                        :editing="false"
                        :name="'videoViews15S'"
                        :className="'flex-container flex-col flex-start-align flex-start-justify'"
                        :labelClassName="'nomargin'"
                    >
                    </text-input>
                </div>
            </div>
        </div> 
</template>
<script>
import {inject} from 'vue'
import TextInput from '@/components/formelements/TextInput'
import {formatNumber} from '@/utils/NumberFunctions'
export default {
    props:{
        insight:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        TextInput
    },
    setup(){
        const campaign = inject('campaign')
       
        
        return{
            campaign,
            formatNumber,
        }
    }
}
</script>