<template>
  <div>
    <button class="fb-button p8" @click.prevent="logInWithFacebook"> {{message}}</button>
  </div>
</template>
<script>
import{ checkLoginStatus, doFbLogin} from './../../../utils/FacebookUtils'
export default {
  props:{
    message:{
      type:String,
      default: 'Login with Facebook'
    }
  },
  emits:['fb-login','fb-no-login'],
  setup( _, {emit} ){

    

    const logInWithFacebook = async ()=> {  
      checkLoginStatus()
        .then(response =>{
        emit('fb-login', response)
      }).catch(()=>{
        doFbLogin({scope: 'pages_show_list, pages_read_engagement, pages_read_user_content'})
        .then(response =>{
          emit('fb-login', response)
        })
        .catch((response)=>{
          emit('fb-no-login', response)
        })
      })
      return false;
    }
    return {
      logInWithFacebook
    }
  }
  
};
</script>
<style>
.fb-button{
  color: white;
  min-width: 150px;
  height: 2.5rem;
  line-height: 24px!important;
  font-weight: 400;
  font-size: 0.8rem;
  border: none;
}
</style>